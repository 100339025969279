body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(26, 29, 32);
  color: rgb(211, 211, 211);
}

.bg-dark {
  background-color: rgb(26, 29, 32) !important;
}

.navbar-dark-icon {
  color: rgb(211, 211, 211);
}

.nav-link {
  margin-right: 10px;
  cursor:pointer;
}

.btn-link {
  color: white;
}

.btn-link:hover {
  color: white;
}

/****
Cards
*****/

.card {
  /* border: thin solid white; */
  border: none;
  color: rgb(211, 211, 211);
  background-color: rgb(45, 45, 45);
  margin-bottom: 15px;
}

.card-header {
  background-color: rgb(100, 4, 4);
  color: white;
  font-weight: bold;
}

/******
Modal
*******/

.modal-header {
  background-color: rgb(100, 4, 4);
  color: white;
  font-weight: bold;
  border-bottom: none;
}

.modal-body {
  color: rgb(211, 211, 211);
  background-color: rgb(76, 76, 76);
  border-bottom: none;
}

.modal-footer {
  color: rgb(211, 211, 211);
  background-color: rgb(76, 76, 76);
  border-top: none;
}

/**
Layout
****/

.form-group {
  margin-bottom: 10px;
}

p.center {
  text-align: center;
}

.row-list {
  padding-bottom: 10px;
}

.row-list-header {
  font-weight: bold;
  text-decoration: underline;
  /* border-bottom: thin solid #6c7278; */
  font-size: 18px;
}

.row-spacing {
  margin-top: 6px;
  margin-bottom: 12px;
}

/**
Accordion
*/
.accordion-header button, .accordion-header button:focus, .accordion-header button:not(.collapsed) {
  color: rgb(211, 211, 211);
  background-color: rgb(45, 45, 45);
  border: none;
}
.accordion-item {
  color: rgb(211, 211, 211);
  background-color: rgb(45, 45, 45);
}